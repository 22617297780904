import { BindParams, AmountStore } from '@/components/AmountWidget/types';
import {
  changeEventOrigin,
  ComponentIds as Ids,
  MsbItemState,
  CustomAmountItem,
} from '@/components/AmountWidget/constants';
import { AmountOption } from '@/common/types';
import { itemAutoSelect } from '@/common/utils/repeater';

const toData = (values: AmountOption[]) =>
  values.map(({ value, amountLabel, amountImpact }, index) => ({
    _id: `amount-${value.replace('_', '-')}`,
    value,
    amountLabel: amountLabel || ' ',
    amountImpact: amountImpact || '',
    index,
  }));

const shouldDeleteImpact = (amountImpact: string | null | undefined) => {
  return !amountImpact || amountImpact === '';
};

export const bindSuggestionsList = ({
  $props,
  $widgetState,
  $w,
  fireChangeEvent,
  isAmountImpactEnabled,
}: AmountStore): BindParams => {
  $w(Ids.CustomAmountFieldInput).hideNumberSpinner();
  const disposeMap = new Map<string, () => void>();
  let isSelectedByMouse = false;

  return {
    [Ids.Repeater]: {
      deleted: () => !($props.options?.length > 0),
      data: () => toData($props.options),
      item: ({ amountLabel, amountImpact, _id, value }, _, currentIndex) => {
        const dispose = itemAutoSelect(
          currentIndex,
          $w(Ids.Repeater),
          $widgetState.list,
        );
        disposeMap.set(_id, dispose);
        return {
          [Ids.RepeaterItem]: {
            inputLabel: () => amountLabel,
          },
          [Ids.multiStateItem]: {
            currentState: () => {
              return value === $widgetState.list.value
                ? MsbItemState.Selected
                : MsbItemState.Default;
            },
            onClick: () => {
              isSelectedByMouse = true;
            },
          },
          [Ids.itemTextDefault]: {
            text: () => amountLabel,
          },
          [Ids.itemTextSelected]: {
            text: () => amountLabel,
          },
          [Ids.impactBoxDefault]: {
            deleted: () => true,
          },
          [Ids.impactBoxSelected]: {
            deleted: () => true,
          },
          [Ids.impactTextDefault]: {
            text: () => amountImpact,
            deleted: () =>
              !isAmountImpactEnabled || shouldDeleteImpact(amountImpact),
          },
          [Ids.impactTextSelected]: {
            text: () => amountImpact,
            deleted: () =>
              !isAmountImpactEnabled || shouldDeleteImpact(amountImpact),
          },
        };
      },
      onItemSelected: (_, { value }) => {
        $widgetState.list.selectedValue = value;

        if (value === CustomAmountItem) {
          $widgetState.customAmount.isSelected = true;
          if (isSelectedByMouse) {
            $w(Ids.CustomAmountFieldInput).focus();
          }

          fireChangeEvent(changeEventOrigin.OtherAmountButton);
        } else {
          $widgetState.customAmount.reset();
          fireChangeEvent(changeEventOrigin.SuggestionsList, value);
        }

        isSelectedByMouse = false;
      },
      onItemRemoved: (item) => {
        try {
          const dispose = disposeMap.get(item?._id);
          if (dispose) {
            dispose();
            disposeMap.delete(item?._id);
          }
        } catch (e) {
          console.error(e);
        }
      },
    } as any,
  };
};

export default bindSuggestionsList;
